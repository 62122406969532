<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Flujo de Ventas - Oportunidades</h4>
            <div class="small text-muted">Seguimiento de venta de oportunidades de negocio.</div>
          </b-col>

          <b-col sm="5">
            <b-dropdown right text="Agregar" variant="primary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar Oportunidad">
              <b-dropdown-header>Existentes</b-dropdown-header>
              <b-dropdown-item @click="addCustomer()"><i class="fa fa-plus"></i> Clientes</b-dropdown-item>
              <b-dropdown-header>Nuevos</b-dropdown-header>
              <b-dropdown-item @click="addPerson()"><i class="fa fa-plus"></i> Persona</b-dropdown-item>
              <b-dropdown-item @click="addCompany()"><i class="fa fa-plus"></i> Empresa</b-dropdown-item>
            </b-dropdown>

            <b-col sm="12" class="d-none d-md-block" v-if="!table.mostrarFiltros">
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" @click="table.mostrarFiltros=true" v-b-tooltip.hover title="Mostrar filtros">
                <i class="fa fa-filter"></i> Mostrar Filtros
              </b-button>
            </b-col>
            <b-col sm="12" class="d-none d-md-block" v-else>
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" v-if="table.mostrarFiltros" @click="table.mostrarFiltros=false" v-b-tooltip.hover title="Ocultar filtros">
                <i class="fa fa-window-close"></i>
              </b-button>
            </b-col>
          </b-col>          
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row v-if="table.mostrarFiltros">        
          <b-col sm="2" class="pl-1">
            <v-select :options="arr.select.staff" v-model="filter.staff" placeholder="Staff" :multiple="true" :select-on-tab="true"></v-select>
          </b-col>        
          <b-col sm="2" v-if="moduleSellersActive" class="pl-0">
            <v-select :options="arr.select.sellers" v-model="filter.sellers" placeholder="Vendedores" :multiple="true" :select-on-tab="true"></v-select>
          </b-col>      
          
          <b-col sm="1" class="pl-0">
            <v-select :options="arr.select.type" v-model="filter.type" placeholder="Tipo" :multiple="false" :select-on-tab="true" class="crud-leads-select"></v-select>
          </b-col> 
          <b-col sm="2" class="pl-0">
            <v-select :options="arr.select.status" v-model="filter.status" placeholder="Etapa" :multiple="true" :select-on-tab="true" class="crud-leads-select"></v-select>
          </b-col>                            
        
          <b-col sm="2" class="pl-0">
            <v-select :options="arr.select.categories" v-model="filter.categories" placeholder="Categoría" :multiple="true" :select-on-tab="true" class="crud-leads-select"></v-select>
          </b-col>   
          <b-col sm="2" class="pl-0">
            <v-select :options="arr.select.sources" v-model="filter.sources" placeholder="Origen" :multiple="true" :select-on-tab="true" class="crud-leads-select"></v-select>
          </b-col>    

          <b-col sm="2" class="pl-0 pr-0">
            <v-select :options="arr.select.archive" v-model="filter.archive" placeholder="Archivados" :multiple="false" :select-on-tab="true" class="crud-leads-select"></v-select>
          </b-col>                        

          <b-col sm="1" class="pl-0 pr-1">
            <b-button variant="outline-dark pull-right" @click="filterQuery()" size="sm" title="Filtrar">
              <b-icon icon="filter" class="mr-1" style="font-size:15px;"></b-icon>  
              Filtrar                      
            </b-button>
          </b-col>                    
        </b-row>
      </b-card>
      
      <b-tabs>
        <b-tab title="Lista" :active="tabs.list" @click="loadSalesFlowList()">

          <b-row class="mt-3">
            <b-col>
              <b-table class="mb-0 table-sales-flow-custom"
                      responsive
                      head-variant="dark"
                      :hover="true"
                      :small="true"
                      :fixed="true"
                      :items="formartedItems"
                      :fields="table.fields"                    
                      selectable
                      select-mode="single"
                      @row-selected="onRowSelected"                     
                      v-if="table.items.length">

                  <template v-slot:table-colgroup="scope">
                    <col
                      v-for="field in scope.fields"                    
                      :key="field.key"
                      :style="{ width: field.width }"
                    >
                  </template>       

                  <template v-slot:row-details="row">
                    <b-card>
                      <b-row>
                        <b-col md="12">
                          <b-form-group label="Seguimiento" style="font-size:15px;">
                            <b-form-textarea                    
                              v-model="crud.formHistory.comment"
                              size="md"
                              placeholder="Ingresar comentario del seguimiento comercial..."
                              rows="5"
                              max-rows="10"
                            ></b-form-textarea>
                          </b-form-group>
                        </b-col>
                        <b-col md="12">
                          <b-button variant="outline-dark" @click="addComment(row.item)" class="w-100" size='md'>
                              Agregar Comentario
                          </b-button>          
                        </b-col>
                      </b-row>
                    </b-card>
                  </template>
                
                  <template v-slot:cell(id)="data">
                    <b>{{ data.item.id }}</b>
                  </template>

                  <template v-slot:cell(date)="data">
                    {{ moment(data.item.date).format('DD MMMM') }}
                  </template>

                  <template v-slot:cell(history)="data">
                    <div v-if="data.item.history && data.item.history.length" :id="'popover-history-' + data.item.id" @click="openHistory(data.item)">
                      <b-icon icon="chat-text"></b-icon>
                      {{data.item.history.length}} 
                    </div>        
                    <b-popover v-if="data.item.history && data.item.history.length" :target="'popover-history-' + data.item.id" variant="default" triggers="hover">                            
                      <div v-html="previewPopoverHistory(data.item)"></div>
                    </b-popover>                                   
                  </template>

                  <template v-slot:cell(name)="data">
                    <div v-if="data.item.iscompany && data.item.customers_id">
                      <span class="crud-leads-table-title-company">Cliente</span> <br>
                      <span class="crud-leads-table-title-name">{{ data.item.customer.name }}</span> <br>                      
                      <div v-if="data.item.customer.data_fiscal && data.item.customer.data_fiscal.fiscal_condition">
                        <span class="crud-leads-table-title">Cond. Fiscal:</span> {{ data.item.customer.data_fiscal.fiscal_condition.name }} <br>
                      </div>
                      <div v-if="data.item.customer.data_fiscal && data.item.customer.data_fiscal.document_type">
                        <span class="crud-leads-table-title">{{ data.item.customer.data_fiscal.document_type.name }}:</span> {{ data.item.customer.data_fiscal.documents_number }} 
                      </div>
                    </div>                    
                    <div v-if="data.item.iscompany && !data.item.customers_id">
                      <span class="crud-leads-table-title-company">Empresa</span> <br>
                      <span class="crud-leads-table-title-name">{{ data.item.name }}</span> <br>
                      <div v-if="data.item.fiscal_condition">
                        <span class="crud-leads-table-title">Cond. Fiscal:</span> {{ data.item.fiscal_condition.name }} <br>
                      </div>
                      <div v-if="data.item.document_fiscal_type">
                        <span class="crud-leads-table-title">{{ data.item.document_fiscal_type.name }}:</span> {{ data.item.documents_fiscal_number }} 
                      </div>
                    </div>
                    <div v-if="!data.item.iscompany && !data.item.customers_id">
                      <span class="crud-leads-table-title-person">Persona</span> <br>
                      <span class="crud-leads-table-title-name">{{ data.item.name }} {{ data.item.last_name }}</span> <br>
                      <div v-if="data.item.document_type">
                        <span class="crud-leads-table-title">{{ data.item.document_type.name }}:</span> {{ data.item.documents_number }} <br>
                      </div>                    
                      <div v-if="data.item.document_fiscal_type">
                        <span class="crud-leads-table-title">{{ data.item.document_fiscal_type.name }}:</span> {{ data.item.documents_fiscal_number }} 
                      </div>
                    </div>
                  </template>

                  <template v-slot:cell(contact)="data">                    
                    <div v-if="data.item.email">
                      <span class="crud-leads-table-title">Email:</span> 
                      <a :href="'mailto:' + data.item.email"> {{ data.item.email }}</a> <br>
                    </div>
                    <div v-if="data.item.mobile">
                      <span class="crud-leads-table-title">Celular:</span> 
                      <a :href="'tel:' + data.item.mobile"> {{ data.item.mobile }}</a> <br>
                    </div>                          
                    <div v-if="data.item.work_phone">
                      <span class="crud-leads-table-title">Laboral:</span> 
                      <a :href="'tel:' + data.item.work_phone"> {{ data.item.work_phone }}</a> <br>
                    </div>        
                    <div v-if="data.item.phone">
                      <span class="crud-leads-table-title">Particular:</span>
                      <a :href="'tel:' + data.item.phone"> {{ data.item.phone }}</a>
                    </div>   

                    <div v-if="data.item.customers_id" class="mt-2">
                      <b-icon icon="table"></b-icon>
                      <b-link href="javascript:void(0)" @click="openContactsCustomers(data.item)">                        
                        Ver Todos los Contactos
                      </b-link>
                    </div>                    
                  </template>

                  <template v-slot:cell(info)="data">
                    <div v-if="data.item.source">
                      <span class="crud-leads-table-title">Origen:</span> {{ data.item.source.name }}
                    </div>
                    <div v-if="data.item.categories">
                      <span class="crud-leads-table-title">Categoría:</span> {{ data.item.categories.name }}
                    </div>                   
                  </template>
                          
                  <template v-slot:cell(status)="data">
                    <div :style="'color:' + getStatusTable(data.item.status).color" class="crud-leads-table-title-status">
                      {{ getStatusTable(data.item.status).label }}
                    </div>

                    <div v-if="getStatusTable(data.item.status).code == 'lost'">
                      <div v-if="data.item.lost_motive">
                        <span class="crud-leads-table-title">Motivo:</span> {{data.item.lost_motive.name}}
                      </div>
                    </div>
                    <div v-if="getStatusTable(data.item.status).code == 'won'">

                      <div v-if="data.item.external_approval">
                        Confirmación Externa                                             
                      </div>                  
                      <div v-if="data.item.firm">
                        <b-link :href="data.item.firm[0]" target="_blank">
                          Ver Firma
                          <b-icon icon="chevron-double-right"></b-icon>
                        </b-link>
                      </div>   
                      <div v-if="data.item.services_customers_id">
                        <b-link @click="goService()">Ver Servicio ID: {{data.item.services_customers_id}}</b-link>
                      </div>
                    </div>                  
                  </template>                                    

                  <template v-slot:cell(responsable)="data">
                    <div v-if="data.item.staff_id" class="mb-1">
                      <span :title="'STAFF: ' + data.item.staff.name">
                        <b-avatar :src="data.item.staff.image"
                                  v-if="data.item.staff.image"
                                  size="sm">
                        </b-avatar>
                        <b-avatar v-else                                                                         
                                  variant="dark"
                                  size="sm">
                        </b-avatar>                                             
                        <b>
                          {{data.item.staff.name}}
                        </b>                                          
                      </span>
                    </div>                  
                    <div v-if="data.item.sellers_id">     
                      <span :title="'VENDEDOR: ' + data.item.seller.staff.name">               
                        <b-avatar :src="data.item.seller.staff.image"
                                  v-if="data.item.seller.staff.image"
                                  size="sm">
                        </b-avatar>
                        <b-avatar v-else                                                                         
                                  variant="dark"
                                  size="sm">
                        </b-avatar>                           
                        <b>
                          {{data.item.seller.staff.name}}
                        </b>  
                      </span>
                    </div>  
                  </template>

                  <template v-slot:cell(budget_id)="data">
                    <div v-if="data.item.budget" 
                        :title="data.item.budget.type_voucher.name + ' ' + data.item.budget.point_sale.point_sale.toString().padStart(4,'0') + '-' + data.item.budget.number.toString().padStart(8,'0')" 
                        style="cursor:pointer;">
                      <b-icon icon="file-earmark-ppt" style="width: 20px; height: 20px;" ></b-icon>                  
                      <br>
                      <b>{{Intl.NumberFormat('es-AR',{style:'currency',currency: data.item.budget.currency.code, maximumFractionDigits: 0}).format(data.item.budget.amount_total)}}</b>
                    </div>        
                  </template>
                                            
                  <template v-slot:cell(f_action)="data">
                    <b-dropdown right 
                                text="Acción" 
                                size="sm" 
                                variant="outline-dark" 
                                class="pull-right"
                                @show="setConfigTableSalesFlowShow"
                                @hide="setConfigTableSalesFlowHide">                                       

                      <b-dropdown-header v-if="data.item.budget">Impresión</b-dropdown-header>                      
                      <b-dropdown-item v-if="data.item.budget" @click="sendPrinterBudget(data.item.budget)">
                        <b-icon icon="printer"></b-icon> Presupuesto
                      </b-dropdown-item>                                                   

                      <b-dropdown-header v-if="(data.item.status=='approval' && parameters.haveExternalApproval) || 
                                               (data.item.status=='won' && parameters.haveContract) ||
                                               (data.item.status=='won' && parameters.haveContract && parameters.haveExternalApproval)"> 
                        Proceso Adiccional
                      </b-dropdown-header>                      
                      <b-dropdown-item @click="externalApproval(data.item)" v-if="data.item.status=='approval' && parameters.haveExternalApproval">
                        <b-icon icon="box-arrow-up-right"></b-icon> Aprobación Externa
                      </b-dropdown-item>       
                      <b-dropdown-item @click="openContract(data.item)" v-if="data.item.status=='won' && parameters.haveContract">
                        <i class="fa fa-print crud-lead-print-icon"></i> Contrato
                      </b-dropdown-item>   
                      <b-dropdown-item @click="openChangeFirm(data.item)" v-if="data.item.status=='won' && parameters.haveContract && parameters.haveExternalApproval">
                        <b-icon icon="pen"></b-icon> Cambiar Firma
                      </b-dropdown-item>     

                      <b-dropdown-header v-if="data.item.status=='won' && parameters.createService">Servicio</b-dropdown-header>                      
                      <b-dropdown-item @click="createService(data.item)" v-if="data.item.status=='won' && parameters.createService && !data.item.services_customers_id">
                        <i class="fa fa-rocket crud-lead-rocket-icon"></i> Crear Servicio
                      </b-dropdown-item>     
                      <b-dropdown-item @click="unassignService(data.item)" v-if="data.item.status=='won' && parameters.createService && data.item.services_customers_id">
                        <i class="fa fa-chain-broken crud-lead-rocket-icon"></i> Desvincular Servicio
                      </b-dropdown-item>                                                                                             
                      
                      <b-dropdown-header>Estados</b-dropdown-header>
                      <b-dropdown-item @click="nextStage(data.item)" v-if="showNextStage(data.item)">
                        <b-icon icon="arrow-return-right"></b-icon> Próxima Etapa
                      </b-dropdown-item>    
                      <b-dropdown-item @click="previousStage(data.item)" v-if="showPreviousStage(data.item)">
                        <b-icon icon="arrow-return-left"></b-icon> Etapa Anterior
                      </b-dropdown-item>                             

                      <b-dropdown-header>Archivado</b-dropdown-header>                                                            
                      <b-dropdown-item @click="archiveLead(data.item)" v-if="!data.item.archive">
                        <b-icon icon="archive"></b-icon> Archivar
                      </b-dropdown-item>                                      
                      <b-dropdown-item @click="archiveLead(data.item)" v-if="data.item.archive">
                        <b-icon icon="back"></b-icon> Restaurar
                      </b-dropdown-item>                                      

                      <b-dropdown-header>Acciones</b-dropdown-header>
                      <b-dropdown-item @click="edit(data.item)">
                        <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                      </b-dropdown-item>
                      <b-dropdown-item @click="remove(data.item)">
                        <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                      </b-dropdown-item>
                    </b-dropdown>
                  </template>
              </b-table>
              <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
            </b-col>
          </b-row>                     
        </b-tab>

        <b-tab title="Kanban" :active="tabs.kanban" @click="loadSalesFlowKanban(true)">
          <kanban-board :stages="kanban.stages" :blocks="kanban.blocks" @update-block="updateBlock" :key="kanban.forceUpdate">
            <div v-for="stage in kanban.stages" :slot="stage" :key="stage" class="w-100">
              <h2>{{ getStatusName(stage) }}</h2>
              <div v-html="getTotalByStatus(stage)" class="w-100 text-right"></div>                
            </div>
            
            <div v-for="block in kanban.blocks" :slot="block.id" :key="block.id" @dblclick="edit(block.data)" title="Doble click para editar">
              <div>                  
                <strong v-if="block.data.iscompany && block.data.customers_id">Cliente</strong>
                <strong v-if="block.data.iscompany && !block.data.customers_id">Empresa</strong>
                <strong v-if="!block.data.iscompany && !block.data.customers_id">Personal</strong>

                <span class="sales-flow-kanban-date">{{ moment(block.data.created_at).fromNow() }}</span>
              </div>
              <div>
                <u v-if="block.data.iscompany && block.data.customers_id">{{ block.data.customer.name }}</u>
                <u v-if="block.data.iscompany && !block.data.customers_id">{{ block.data.name }}</u>     
                <u v-if="!block.data.iscompany && !block.data.customers_id">{{ block.data.name }} {{ block.data.last_name }}</u>     
              </div>
              <div>{{ block.data.source.name }} | {{ block.data.categories.name }}</div>
              <div>
                <span v-if="block.data.history && block.data.history.length" :id="'popover-kanban-history-' + block.data.id" @click="openHistory(block.data)" style="z-index:9999; cursor:pointer;">
                  <b-icon icon="chat-text"></b-icon>
                  {{block.data.history.length}} 
                </span>        
                <b-popover v-if="block.data.history && block.data.history.length" :target="'popover-kanban-history-' + block.data.id" variant="default" triggers="hover">                            
                  <div v-html="previewPopoverHistory(block.data)"></div>
                </b-popover>                   

                <span v-if="block.data.budget" 
                    :title="block.data.budget.type_voucher.name + ' ' + block.data.budget.point_sale.point_sale.toString().padStart(4,'0') + '-' + block.data.budget.number.toString().padStart(8,'0')" 
                    style="cursor:pointer;"
                    class="ml-2">
                  <b-icon icon="file-earmark-ppt" style="width: 15px; height: 15px;" class="mr-2"></b-icon>    
                  <b>{{Intl.NumberFormat('es-AR',{style:'currency',currency: block.data.budget.currency.code, maximumFractionDigits: 0}).format(block.data.budget.amount_total)}}</b>              
                </span>                                
              </div>
              <div class="mt-1">
                <span v-if="block.data.staff_id">
                  <span :title="'STAFF: ' + block.data.staff.name">
                    <b-avatar :src="block.data.staff.image"
                              v-if="block.data.staff.image"
                              size="sm">
                    </b-avatar>
                    <b-avatar v-else                                                                         
                              variant="dark"
                              size="sm">
                    </b-avatar>                                             
                    <b>
                      {{block.data.staff.name}}
                    </b>                                          
                  </span>
                </span>                  
                <span v-if="block.data.sellers_id">     
                  <span :title="'VENDEDOR: ' + block.data.seller.staff.name">               
                    <b-avatar :src="block.data.seller.staff.image"
                              v-if="block.data.seller.staff.image"
                              size="sm">
                    </b-avatar>
                    <b-avatar v-else                                                                         
                              variant="dark"
                              size="sm">
                    </b-avatar>                           
                    <b>
                      {{block.data.seller.staff.name}}
                    </b>  
                  </span>
                </span>                                                        
              </div>
              <hr class="sales-flow-kanban-hr">
              <div v-html="block.data.work_recurrent?'Servicio Recurrente':'Proyecto'"></div>      
              <div v-if="block.data.status == 'lost' && block.data.lost_motive">
                <u>Motivo:</u> {{block.data.lost_motive.name}}
              </div>
              <div class="mt-2">
                <b-link @click="goService()"
                        v-if="block.data.status=='won' && parameters.createService && block.data.services_customers_id"
                        class="crud-lead-kanban-services">
                  Ver Servicio ID: {{block.data.services_customers_id}}
                </b-link>                  
                <b-link v-if="block.data.status=='won' && parameters.createService && !block.data.services_customers_id" 
                        class="crud-lead-kanban-services">
                  Servicio Sin Crear
                </b-link>
                
                <b-link @click="createService(block.data)" 
                        class="pull-right crud-lead-kanban-create"
                        v-if="block.data.status=='won' && parameters.createService && !block.data.services_customers_id">
                  Crear Servicio
                </b-link>
                <b-link @click="unassignService(block.data)" 
                        class="pull-right crud-lead-kanban-unassign"
                        v-if="block.data.status=='won' && parameters.createService && block.data.services_customers_id">
                  Desvincular Servicio
                </b-link>
              </div>                         
            </div>                            
          </kanban-board>
        </b-tab>
      </b-tabs>      

      <b-card header-tag="header" footer-tag="footer" class="mt-2">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>       
        </b-row>
      </b-card>

      <!--
      ###########################
      ######### PRINTER #########
      ###########################
      -->
      <Printer v-if="crud.print.id" 
              :reference="crud.print.reference" 
              :registerID="crud.print.id"
              :key="crud.print.key"/>

      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD MAIN -->
      <b-modal v-model="modal.form.active"
              no-close-on-esc
              no-close-on-backdrop
              hide-header-close      
              header-bg-variant="dark"              
              header-text-variant="white"
              size="xl">
        <div slot="modal-header">
          {{this.modal.form.title}}
        </div>

        <b-card no-body>          
          <b-tabs active-nav-item-class="font-weight-bold text-uppercase text-info" active-tab-class="crud-leads-bg-form" justified>    
            <b-tab title="Cliente" active v-if="isCompany && isCustomer">   
              <b-card-text>
                <b-row>
                  <b-col md="12">
                    <FindObject render="search"
                                type="customers" 
                                @select-object="loadCustomers($event)" 
                                :valueID="crud.form.customers_id"
                                :where="conditionCustomersActive"
                                description="Cargar el cliente de la oportunidad del negocio"/>           
                  </b-col>                  
                  <b-col md="6">
                    <b-form-group label="Categoría">
                      <v-select :options="arr.select.categories" v-model="crud.form.categories_id" placeholder="Categoría" :multiple="false" :select-on-tab="true" class="crud-leads-select"></v-select>
                    </b-form-group>   
                  </b-col> 
                  <b-col md="6">
                    <b-form-group label="Origen">
                      <v-select :options="arr.select.sources" v-model="crud.form.sources_id" placeholder="Origen" :multiple="false" :select-on-tab="true" class="crud-leads-select"></v-select>
                    </b-form-group>   
                  </b-col>
                </b-row>
              </b-card-text>
            </b-tab>

            <b-tab title="Persona" v-if="!isCompany && !isCustomer">
              <b-card-text>
                <b-row>
                  <b-col md="6">
                    <b-form-group label="Nombre">
                      <b-form-input type="text"
                                    size="md"
                                    v-model="crud.form.name">
                      </b-form-input>           
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Apellido">
                      <b-form-input type="text"
                                    size="md"
                                    v-model="crud.form.last_name">
                      </b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col md="6">
                    <b-form-group label="Tipos de Documentos">
                      <v-select :options="arr.select.typesDocuments" v-model="crud.form.documents_types_id" placeholder="Tipos Documentos" :multiple="false" :select-on-tab="true" class="crud-leads-select"></v-select>
                    </b-form-group>
                  </b-col>   
                  <b-col md="6">
                    <b-form-group label="Número Documento">
                      <b-form-input type="text"
                                    size="md"
                                    v-model="crud.form.documents_number">
                      </b-form-input>
                    </b-form-group>                    
                  </b-col>  

                  <b-col md="6">
                    <b-form-group label="Tipos de Documentos Fiscales">
                      <v-select :options="arr.select.typesDocumentsFiscal" v-model="crud.form.documents_fiscal_types_id" placeholder="Tipos Documentos Fiscales" :multiple="false" :select-on-tab="true" class="crud-leads-select"></v-select>
                    </b-form-group>
                  </b-col>  
                  <b-col md="6">
                    <b-form-group label="Número Documento Fiscal">
                      <b-form-input type="text"
                                    size="md"
                                    v-model="crud.form.documents_fiscal_number">
                      </b-form-input>
                    </b-form-group>                    
                  </b-col> 

                  <b-col md="6">
                    <b-form-group label="Categoría">
                      <v-select :options="arr.select.categories" v-model="crud.form.categories_id" placeholder="Categoría" :multiple="false" :select-on-tab="true" class="crud-leads-select"></v-select>
                    </b-form-group>   
                  </b-col> 
                  <b-col md="6">
                    <b-form-group label="Origen">
                      <v-select :options="arr.select.sources" v-model="crud.form.sources_id" placeholder="Origen" :multiple="false" :select-on-tab="true" class="crud-leads-select"></v-select>
                    </b-form-group>   
                  </b-col>

                  <b-col md="12">
                    <hr>
                  </b-col>

                  <b-col md="12">
                    <b-form-group label="Actividad Principal">
                      <b-form-input type="text"
                                    size="md"
                                    v-model="crud.form.main_activity">
                      </b-form-input>
                    </b-form-group>                    
                  </b-col> 
                  <b-col md="12">
                    <b-form-group label="Observaciones">
                      <b-form-textarea                    
                        v-model="crud.form.observations"
                        size="md"
                        placeholder="Ingresar las observaciones..."
                        rows="3"
                        max-rows="6"
                      ></b-form-textarea>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-text>
            </b-tab>

            <b-tab title="Empresa" active v-if="isCompany && !isCustomer">   
              <b-card-text>
                <b-row>
                  <b-col md="6">
                    <b-form-group label="Razón Social">
                      <b-form-input type="text"
                                    size="md"
                                    v-model="crud.form.name">
                      </b-form-input>           
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Condición Fiscal">
                      <v-select :options="arr.select.fiscalConditions" v-model="crud.form.fiscal_conditions_id" placeholder="Condición Fiscal" :multiple="false" :select-on-tab="true" class="crud-leads-select"></v-select>
                    </b-form-group>
                  </b-col> 

                  <b-col md="6">
                    <b-form-group label="Tipos de Documentos Fiscales">
                      <v-select :options="arr.select.typesDocumentsFiscal" v-model="crud.form.documents_fiscal_types_id" placeholder="Tipos Documentos Fiscales" :multiple="false" :select-on-tab="true" class="crud-leads-select"></v-select>
                    </b-form-group>
                  </b-col>  
                  <b-col md="6">
                    <b-form-group label="Número Documento Fiscal">
                      <b-form-input type="text"
                                    size="md"
                                    v-model="crud.form.documents_fiscal_number">
                      </b-form-input>
                    </b-form-group>                    
                  </b-col> 

                  <b-col md="6">
                    <b-form-group label="Categoría">
                      <v-select :options="arr.select.categories" v-model="crud.form.categories_id" placeholder="Categoría" :multiple="false" :select-on-tab="true" class="crud-leads-select"></v-select>
                    </b-form-group>   
                  </b-col> 
                  <b-col md="6">
                    <b-form-group label="Origen">
                      <v-select :options="arr.select.sources" v-model="crud.form.sources_id" placeholder="Origen" :multiple="false" :select-on-tab="true" class="crud-leads-select"></v-select>
                    </b-form-group>   
                  </b-col>

                  <b-col md="12">
                    <hr>
                  </b-col>

                  <b-col md="12">
                    <b-form-group label="Actividad Principal">
                      <b-form-input type="text"
                                    size="md"
                                    v-model="crud.form.main_activity">
                      </b-form-input>
                    </b-form-group>                    
                  </b-col> 
                  <b-col md="12">
                    <b-form-group label="Observaciones">
                      <b-form-textarea                    
                        v-model="crud.form.observations"
                        size="md"
                        placeholder="Ingresar las observaciones..."
                        rows="3"
                        max-rows="6"
                      ></b-form-textarea>
                    </b-form-group>
                  </b-col>                                    
                </b-row>
              </b-card-text>
            </b-tab>

            <b-tab title="Ubicación" v-if="!isCustomer">               
              <b-card-text>
                <b-row>
                  <b-col md="6">
                    <b-form-group label="Dirección">
                      <b-form-input type="text"
                                    size="md"
                                    v-model="crud.form.address">
                      </b-form-input>
                    </b-form-group>                    
                  </b-col>          
                  <b-col md="4">
                    <b-form-group label="Ciudad">
                      <b-form-input type="text"
                                    size="md"
                                    v-model="crud.form.city">
                      </b-form-input>
                    </b-form-group>                    
                  </b-col>      
                  <b-col md="2">
                    <b-form-group label="Código Postal">
                      <b-form-input type="text"
                                    size="md"
                                    v-model="crud.form.postal_code">
                      </b-form-input>
                    </b-form-group>                    
                  </b-col>  
                  <b-col md="6">
                    <b-form-group label="Provincia">
                      <b-form-input type="text"
                                    size="md"
                                    v-model="crud.form.state">
                      </b-form-input>
                    </b-form-group>                    
                  </b-col>  
                  <b-col md="6">
                    <b-form-group label="País">
                      <b-form-input type="text"
                                    size="md"
                                    v-model="crud.form.country">
                      </b-form-input>
                    </b-form-group>                    
                  </b-col>                    
                </b-row>             
              </b-card-text>
            </b-tab>
            
            <b-tab title="Contacto">   
              <b-card-text>
                <b-row>     
                  <b-col md="6">
                    <b-form-group label="Contacto">
                      <b-form-input type="text"
                                    size="md"
                                    v-model="crud.form.contact">
                      </b-form-input>
                    </b-form-group>                    
                  </b-col>                   
                  <b-col md="6">
                    <b-form-group label="Email">
                      <b-form-input type="text"
                                    size="md"
                                    v-model="crud.form.email">
                      </b-form-input>
                    </b-form-group>                    
                  </b-col> 

                  <b-col md="12">
                    <hr>
                  </b-col>

                  <b-col md="4">
                    <b-form-group label="Teléfono Particular">
                      <b-form-input type="number"                                    
                                    size="md"
                                    v-model="crud.form.phone">
                      </b-form-input>
                    </b-form-group>                    
                  </b-col> 
                  <b-col md="4">
                    <b-form-group label="Teléfno Laboral/Comercial">
                      <b-form-input type="number"
                                    size="md"
                                    v-model="crud.form.work_phone">
                      </b-form-input>
                    </b-form-group>                    
                  </b-col>       
                  <b-col md="4">
                    <b-form-group label="Teléfono Celular">
                      <b-form-input type="number"
                                    size="md"
                                    v-model="crud.form.mobile">
                      </b-form-input>
                    </b-form-group>                    
                  </b-col>
                </b-row>
              </b-card-text>
            </b-tab>    
            
            <b-tab title="Oportunidad de Negocio">
              <b-row>                
                <b-col md="12">
                  <b-form-checkbox v-model="crud.form.work_recurrent" 
                                    switch 
                                    size="sm"
                                    class="mt-2"
                                    @input="selectBudgets(crud.form.customers_id)">
                    Venta Recurrente
                  </b-form-checkbox>            
                </b-col>                          
                <b-col md="12" class="mt-3">
                  <b-form-group label="Necesidad">
                    <b-form-textarea                    
                      v-model="crud.form.work_budget"
                      size="md"
                      placeholder="Ingresar las descripción de la necesidad..."
                      rows="5"
                      max-rows="10"
                    ></b-form-textarea>
                  </b-form-group>
                </b-col>     
                <b-col md="12">
                  <b-form-checkbox v-model="crud.form.load_budget" 
                                    switch 
                                    size="sm"
                                    class="mt-2 mb-2">
                    Vincular un Presupuesto
                  </b-form-checkbox>            
                </b-col>                       
                <b-col md="12" v-if="crud.form.load_budget">
                  <b-form-group label="Presupuesto">
                    <v-select :options="arr.select.budgets" v-model="crud.form.budget_id" placeholder="Presupuestos" :multiple="false" :select-on-tab="true" class="crud-leads-select"></v-select>
                  </b-form-group>
                </b-col>                   
              </b-row>
            </b-tab>                  
          </b-tabs>
        </b-card>    

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.form.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="save()">Guardar</b-button>          
        </div>
      </b-modal>

      <!-- CONTACTS CUSTOMERS-->
      <b-modal v-model="modal.contacts.active"
              header-bg-variant="dark"              
              header-text-variant="white"
              size="md">
        <div slot="modal-header">
          {{this.modal.contacts.title}}
        </div>
        
        <b-row v-if="modal.contacts.customer">
          <b-col md="12">
            <Contacts :customers="modal.contacts.customer" :customersID="modal.contacts.customer.id"></Contacts>
          </b-col>
        </b-row>                    
        
        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.contacts.active = false">Cerrar</b-button>          
        </div>
      </b-modal>      
      
      <!-- HISTORY-->
      <b-modal v-model="modal.history.active"
              header-bg-variant="dark"              
              header-text-variant="white"
              size="lg">
        <div slot="modal-header">
          {{this.modal.history.title}}
        </div>
        
        <b-row>
          <b-col md="12">
            <b-table class="mb-0 table-sales-flow-custom"
                      responsive
                      head-variant="dark"
                      style="min-height: 100px;"
                      :hover="true"
                      :small="true"
                      :fixed="true"
                      :items="tableHistory.items"
                      :fields="tableHistory.fields"                                                           
                      v-if="tableHistory.items.length">

                  <template v-slot:table-colgroup="scope">
                    <col
                      v-for="field in scope.fields"                    
                      :key="field.key"
                      :style="{ width: field.width }"
                    >
                  </template>     
                  
                  <template v-slot:cell(date)="data">
                    {{ moment(data.item.date).format('DD MMMM') }}
                  </template>     

                  <template v-slot:cell(staff_id)="data">
                    <div v-if="data.item.staff_id" class="mb-1">
                      <span :title="'STAFF: ' + data.item.staff.name">
                        <b-avatar :src="data.item.staff.image"
                                  v-if="data.item.staff.image"
                                  size="sm">
                        </b-avatar>
                        <b-avatar v-else                                                                         
                                  variant="dark"
                                  size="sm">
                        </b-avatar>                                             
                        <b>
                          {{data.item.staff.name.trim().split(/\s+/).map(palabra => palabra.charAt(0).toUpperCase()).join("")}}
                        </b>                                          
                      </span>
                    </div>        
                  </template>   

                  <template v-slot:cell(f_action)="data">
                    <b-dropdown right 
                                text="" 
                                size="sm" 
                                variant="outline-dark" 
                                class="pull-right">                                       
                                                                                        
                      <b-dropdown-header>Acciones</b-dropdown-header>
                      <b-dropdown-item @click="removeComment(data.item)">
                        <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                      </b-dropdown-item>
                    </b-dropdown>
                  </template>                                       
              </b-table>            
          </b-col>
        </b-row>                    
        
        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.history.active = false">Cerrar</b-button>          
        </div>
      </b-modal>      

      <!-- CRUD GANADO O PERDIDO -->
      <b-modal v-model="modal.stage.active"
              no-close-on-esc
              no-close-on-backdrop
              hide-header-close            
              header-bg-variant="dark"              
              header-text-variant="white"
              size="md">
        <div slot="modal-header">
          {{this.modal.stage.title}}
        </div>
        
        <b-row>
          <b-col md="12">
            <b-button-group style="width: 100%;">
              <b-button variant="success" @click="modal.stage.value='won'">Ganado</b-button>
              <b-button variant="danger" @click="modal.stage.value='lost'">Perdido</b-button>            
            </b-button-group>
          </b-col> 

          <b-col md="12" v-if="modal.stage.value=='won'" class="mt-3">
            <div class="crud-leads-modal-customer-won">
              <i class="fa fa-thumbs-up"></i> Cliente Ganado!!
            </div>
          </b-col>

          <b-col md="12" v-if="modal.stage.value=='lost'" class="mt-3">
            <div class="crud-leads-modal-customer-lost">
              <i class="fa fa-thumbs-down"></i> Cliente Perdido                  
            </div>
          </b-col>            
          <b-col md="12" v-if="modal.stage.value=='lost'" class="mt-3">
            <b-form-group label="Motivo de Pérdida">
              <v-select :options="arr.select.lostMotives" v-model="crud.form.lost_motives_id" placeholder="Motivo de Pérdida" :multiple="false" :select-on-tab="true" class="crud-leads-select"></v-select>
            </b-form-group>                 
          </b-col>
        </b-row>                    
        
        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="closeStageFinish()">Cancelar</b-button>
          <b-button variant="dark" @click="saveStageFinish()">Guardar</b-button>          
        </div>
      </b-modal> 

      <!-- CONTRACT -->
      <b-modal v-model="modal.contract.active"
              header-bg-variant="dark"              
              header-text-variant="white"
              size="xl">
        <div slot="modal-header">
          {{this.modal.contract.title}}
        </div>
                
        <b-row>
          <b-col lg="12" v-if="modal.contract.leadObject">
            <h5>
              {{this.modal.contract.leadObject.name}}
              <b-badge v-if="modal.contract.leadObject.iscompany" variant="danger">Empresa</b-badge>
              <b-badge v-else variant="primary">Persona</b-badge>
            </h5>
          </b-col>

          <b-col lg="4">            
            <v-select :options="arr.select.contracts" 
                      v-model="modal.contract.contractObject" 
                      placeholder="Contratos" 
                      :multiple="false" 
                      :select-on-tab="true" 
                      class="crud-leads-select"
                      @change="showContract">
            </v-select>            
          </b-col>
          <b-col lg="8" v-if="modal.contract.urlPDF" class="crud-leads-preview-open-pdf">
            <strong>
              <b-link :href="modal.contract.urlPDF" target="_blank">
                Abrir PDF ({{this.modal.contract.contractObject.label}})
                <b-icon icon="chevron-double-right"></b-icon>
              </b-link>
            </strong>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col lg="12">      
            <div class="crud-leads-preview-contract">
              <div v-if="modal.contract.content" 
                  class="crud-leads-preview-contract-html" 
                  v-html="modal.contract.content">
              </div>
              <div v-else 
                  class="crud-leads-preview-contract-alert">
                <b-alert show variant="primary">Seleccion un formato de contrato</b-alert>
              </div>
            </div>
          </b-col>          
        </b-row>                    
        
        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.contract.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="printContract()">Crear Contrato</b-button>          
        </div>
      </b-modal>

      <!-- FIRMA -->
      <b-modal v-model="modal.firm.active"
              header-bg-variant="dark"              
              header-text-variant="white"
              size="sm">
        <div slot="modal-header">
          {{this.modal.firm.title}}
        </div>
                
        <b-row>
          <b-col lg="12">
            <b-form-group label="Firmar" 
                          description="Firme en una hoja en blanco y luego suba una foto de la misma (100px alto / 150px ancho)"
                          class="sales-flow-leads-label-custom">
              <Imagen :images="modal.firm.imagen" 
                      @load-image="modal.firm.imagen = $event" 
                      typeImage="Firma" 
                      class="sales-flow-leads-image"/>                        
            </b-form-group>            
          </b-col>         
        </b-row>
        
        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.firm.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="changeFirm()">Cambiar Firma</b-button>          
        </div>
      </b-modal>
    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import ErrorToken from '@/handler/errorToken'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import moment from 'moment'
  import FindObject from '@/components/inc/find/findObject' 
  import Vue from 'vue'
  import Param from '@/config/parameters'
  import Imagen from '@/components/inc/image/image' 
  import Storage from '@/handler/storageLocal'  
  import vueKanban from 'vue-kanban'
  import Contacts from '@/components/modules/users/_access/customer/staff/contacts/crud'
  import Printer from '@/components/inc/printer/printer'

  Vue.use(vueKanban)

  export default {
    components: {
      FindObject,
      Imagen,
      Contacts,
      Printer,
    },      
    data: () => {
      return {      
        access: {
          module_id: Modules.FLUJO_VENTAS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'crudLeadsServices',
          elements: {}
        }, 
        parameters: {                    
          haveExternalApproval: Helper.hasParametersAccess(Param.P6),
          haveContract: Helper.hasParametersAccess(Param.P16),
          createService: Helper.hasParametersAccess(Param.P23),
        },            
        table : {
          items: [],
          fields: [],          
          mostrarFiltros: true,
          currentPage: 1,
          perPage: 50,
        },   
        tableHistory: {
          items: [],
          fields: [            
            {key: 'date', label: 'Fecha', class: "align-middle", width:"10%"},
            {key: 'comment', label: 'Comentario', class: "align-middle", width:"70%"},
            {key: 'staff_id', label: 'Responsable', class: "align-middle", width:"15%"},            
            {key: 'f_action', label:'', class: "align-middle", width:"5%"}
          ],
        },         
        crud: {
          form: {
            id: 0,
            date: '',                                   
            name: '',
            last_name: '',
            address: '',
            city: '',
            postal_code: '',
            state: '',
            country: '',
            fiscal_conditions_id: 0,
            documents_types_id: 0,
            documents_number: '',
            documents_fiscal_types_id: 0,
            documents_fiscal_number: '',
            marital_status_id: 0,
            nationality: '',
            place_birth: '',
            birthday: null,
            gender: '',
            main_activity: '',
            phone: '',
            work_phone: '',
            mobile: '',
            email: '',
            status: '',
            address_correspondence: '',           
            registration_public_registry: null,
            year_end_date: null,
            registration_district: '',
            registration_number: '',
            registration_date: null,
            registration_book: '',
            registration_volume: '',
            registration_sheet: '',
            observations: '',        
            lost_motives_id: 0,
            categories_id: 0,
            sources_id: 0,
            staff_id: 0,
            staff: null,
            sellers_id: 0,  
            sellers: null,  
            iscompany: false,
            iscustomer: false,
            work_price: 0,
            work_budget: '',
            work_currency_id: 1,
            currency: null,
            work_recurrent: false,
            customers: null,
            customers_id: 0,
            contact: '',
            budget_id: 0,
            load_budget: true,
          },          
          formHistory: {
            leads_id: 0,
            comment: '',
          },
          print: {
            reference: 'budget',
            id: 0,
            key: 0,
          },                        
        },
        modal: {
          form: {
            active: false,
            title: ''
          },                
          stage: {
            active: false,
            title: '',
            value: '',
            id: 0
          },
          contacts: {
            active: false,
            title: '',
            customer: null
          },     
          history: {
            active: false,
            title: '',
          },                       
          contract: {
            active: false,
            title: '',
            contractObject: null,
            leadID: 0,
            content: '',
            urlPDF: '',
            leadObject: null,
          },
          firm: {
            active: false,
            title: '',
            image: [],
            leadID: 0,            
          }
        },
        arr: {
          select : {                        
            staff: [],
            sellers: [],
            gender: [],
            status: [],
            lostMotives: [],
            categories: [],
            sources: [],
            fiscalConditions: [],
            typesDocuments: [],
            typesDocumentsFiscal: [],
            maritalStatus: [],    
            type: [],
            contracts: [],  
            budgets: [],      
            archive: [],
          },
          leads: [],
          contracts: [],
        },
        filter: {                    
          staff: [],
          sellers: [],          
          type: [],
          categories: [],
          sources: [],
          status: [],
          archive: { code: false, label: "VISIBLES"},
        },        
        kanban: {
          stages: [],
          blocks: [],
          forceUpdate: 0.
        },
        tabs: {
          list: true,
          kanban: false,          
        }              
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    mounted() {            
      this.setFieldTable()
      this.selectLoad()
      this.filterLoad()
      this.filterQuery()

      this.restoreStatusTabsStorage()
    },
    computed: {
      // MODULES
      moduleSellersActive() {
        var status = false
        var modules = Session.getSession().auth.user.permissions.modules        
        modules.forEach(element => {
          if(element.id == Modules.VENDEDORES) {                             
            status = true                       
          }
        })        
        return status
      },

      formartedItems () {
        if (!this.table.items) return []
        return this.table.items.map(item => {
          return item
        })
      },
      isCompany () {
        if(this.crud.form.iscompany) {
          return true
        } else {
          return false
        }
      }, 
      isCustomer () {
        if(this.crud.form.iscustomer) {
          return true
        } else {
          return false
        }
      },
      conditionCustomersActive(){
        return [{field: 'active', condition: true}];
      },
    },      
    watch: {        
      'arr.leads': function (newQuestion, oldQuestion) {        
        this.kanban.forceUpdate = this.kanban.forceUpdate + 1
        this.loadSalesFlowKanban()
      }
    }, 
    methods: {
      // CARGAR CAMPOS TABLA
      setFieldTable(){
        this.table.fields.push({key: 'id', label: 'ID', sortable: true, class: "align-middle", width:"50px"})
        this.table.fields.push({key: 'date', label: 'Fecha', class: "align-middle", width:"100px"})
        this.table.fields.push({key: 'history', label: '', class: "align-middle", width:"50px"})
        this.table.fields.push({key: 'name', label: 'Nombre', class: "align-middle", width:"250px"})
        this.table.fields.push({key: 'contact', label: 'Contacto', class: "align-middle", width:"250px"})
        this.table.fields.push({key: 'info', label: 'Info', class: "align-middle", width:"250px"})        
        this.table.fields.push({key: 'responsable', label: 'Responsable', class: "align-middle", width:"150px"})
        this.table.fields.push({key: 'status', label: 'Etapa', class: "align-middle text-center", width:"150px"})
        this.table.fields.push({key: 'budget_id', label: 'Presupuesto', class: "align-middle text-center", width:"80px"})
        this.table.fields.push({key: 'f_action', label:'', class: "align-middle", width:"100px"})
      },
      setConfigTableSalesFlowShow() {
        var arrTable = document.getElementsByClassName('table-sales-flow-custom')
        var i = 0
        for(i=0; i<arrTable.length; i++) {
          if(this.parameters.haveNroInteral) {
            arrTable[i].style.minHeight = '390px'
          } else {
            arrTable[i].style.minHeight = '320px'
          }          
        }        
      },
      setConfigTableSalesFlowHide() {
        var arrTable = document.getElementsByClassName('table-sales-flow-custom')
        var i = 0
        for(i=0; i<arrTable.length; i++) {
          arrTable[i].style.minHeight = 'auto'
        }        
      }, 

      // CONFIG OPEN DETAIL TABLE
      getRowCount (items) {
        return items.length
      },
      onRowSelected(item) {              
        this.arr.leads.forEach((element, index) => {
          if(item.length){
            if(element.id == item[0].id) {
              this.openDetail(index)
            }
          }
        }) 
      },     
      openDetail(index) {                                
        this.table.rowSelected = index
        this.filterQuery()
      },        
      
      // FORM MAIN
      addCustomer() {
        this.crud.form.iscompany = true
        this.crud.form.iscustomer = true
        this.add()
      },
      addPerson() {        
        this.crud.form.iscompany = false
        this.crud.form.iscustomer = false

        this.selectBudgets()

        this.add()
      },
      addCompany() {
        this.crud.form.iscompany = true
        this.crud.form.iscustomer = false

        this.selectBudgets()

        this.add()
      },
      add() {        
        this.crud.form.id = 0
        this.crud.form.date = moment().format('YYYY-MM-DD')                                  
        this.crud.form.name = ''
        this.crud.form.last_name = ''
        this.crud.form.address = ''
        this.crud.form.city = ''
        this.crud.form.postal_code = ''
        this.crud.form.state = ''
        this.crud.form.country = ''
        this.crud.form.fiscal_conditions_id = null
        this.crud.form.documents_types_id = null
        this.crud.form.documents_number = ''
        this.crud.form.documents_fiscal_types_id = null
        this.crud.form.documents_fiscal_number = ''
        this.crud.form.marital_status_id = null
        this.crud.form.nationality = ''
        this.crud.form.place_birth = ''
        this.crud.form.birthday = null
        this.crud.form.gender = ''
        this.crud.form.main_activity = ''
        this.crud.form.phone = ''
        this.crud.form.work_phone = ''
        this.crud.form.mobile = ''
        this.crud.form.email = ''
        this.crud.form.status = ''
        this.crud.form.address_correspondence = ''    
        this.crud.form.registration_public_registry = null
        this.crud.form.year_end_date = null
        this.crud.form.registration_district = ''
        this.crud.form.registration_number = ''
        this.crud.form.registration_date = null
        this.crud.form.registration_book = ''
        this.crud.form.registration_volume = ''
        this.crud.form.registration_sheet = ''
        this.crud.form.observations = ''    
        this.crud.form.lost_motives_id = null
        this.crud.form.categories_id = null
        this.crud.form.sources_id = null

        this.crud.form.staff_id = Helper.getStaffId()        
        this.crud.form.sellers_id = null
        this.crud.form.customers_id = null
        
        this.crud.form.work_price = 0
        this.crud.form.work_budget = ''
        this.crud.form.work_currency_id = 1
        this.crud.form.work_recurrent = false

        this.crud.form.contact = ''
        this.crud.form.budget_id = null   
        this.crud.form.load_budget = true

        this.modal.form.title = "Nueva Oportunidad"
        this.modal.form.active = true
      },
      edit(item) {
        this.crud.form.iscompany = item.iscompany
        this.crud.form.iscustomer = item.customers_id ? true : false

        this.crud.form.id = item.id      
        this.crud.form.date = item.date
        this.crud.form.name = item.name
        this.crud.form.last_name = item.last_name
        this.crud.form.address = item.address
        this.crud.form.city = item.city
        this.crud.form.postal_code = item.postal_code
        this.crud.form.state = item.state
        this.crud.form.country = item.country
        
        if(item.marital_status) {
          this.crud.form.marital_status_id = { code: item.marital_status.id, label: item.marital_status.name }
        } else {
          this.crud.form.marital_status_id = null
        }

        if(item.fiscal_condition) {
          this.crud.form.fiscal_conditions_id = { code: item.fiscal_condition.id, label: item.fiscal_condition.name }
        } else {
          this.crud.form.fiscal_conditions_id = null
        }
        
        if(item.document_type) {
          this.crud.form.documents_types_id = { code: item.document_type.id, label: item.document_type.name } 
        } else {
          this.crud.form.documents_types_id = null
        }                

        if(item.document_fiscal_type) {
          this.crud.form.documents_fiscal_types_id = { code: item.document_fiscal_type.id, label: item.document_fiscal_type.name }
        } else {
          this.crud.form.documents_fiscal_types_id = null
        }
                
        this.crud.form.documents_number = item.documents_number
        this.crud.form.documents_fiscal_number = item.documents_fiscal_number 
        this.crud.form.nationality = item.nationality
        this.crud.form.place_birth = item.place_birth
        this.crud.form.birthday = item.birthday
        this.crud.form.gender = this.getGenderSelect(item.gender)
        this.crud.form.main_activity = item.main_activity
        this.crud.form.phone = item.phone
        this.crud.form.work_phone = item.work_phone
        this.crud.form.mobile = item.mobile
        this.crud.form.email = item.email
        this.crud.form.status = this.getStatusSelect(item.status)
        this.crud.form.address_correspondence = item.address_correspondence    
        this.crud.form.registration_public_registry = item.registration_public_registry
        this.crud.form.year_end_date = item.year_end_date
        this.crud.form.registration_district = item.registration_district
        this.crud.form.registration_number = item.registration_number
        this.crud.form.registration_date = item.registration_date
        this.crud.form.registration_book = item.registration_book
        this.crud.form.registration_volume = item.registration_volume
        this.crud.form.registration_sheet = item.registration_sheet
        this.crud.form.observations = item.observations 

        if(item.lost_motive) {
          this.crud.form.lost_motives_id = { code: item.lost_motive.id, label: item.lost_motive.name }          
        } else {
          this.crud.form.lost_motives_id = null
        }

        if(item.categories) {
          this.crud.form.categories_id = { code: item.categories.id, label: item.categories.name }           
        } else {
          this.crud.form.categories_id = null
        }

        if(item.source) {
          this.crud.form.sources_id = { code: item.source.id, label: item.source.name }
        } else {
          this.crud.form.sources_id = null
        }               
        
        this.crud.form.staff_id = item.staff_id
        this.crud.form.sellers_id = item.sellers_id    
        this.crud.form.customers_id = item.customers_id

        this.crud.form.work_price = item.work_price
        this.crud.form.work_budget = item.work_budget
        this.crud.form.work_currency_id = item.work_currency_id
        this.crud.form.work_recurrent = item.work_recurrent

        this.crud.form.contact = item.contact

        if(!item.customers_id) {
          if(item.budget) {
            this.selectBudgets(null, item.budget)
          } else {
            this.selectBudgets()
          }
        } else {
          if(item.budget) {
            this.selectBudgets(item.customers_id, item.budget)
          }
        }

        this.crud.form.load_budget = item.load_budget
        
        this.modal.form.title = "Editar Oportunidad"
        this.modal.form.active = true
      },
      remove(item) {
        this.crud.form.id = item.id
        this.crud.form.name = item.name

        this.$bvModal.msgBoxConfirm('¿Desea borrar el item (' + this.crud.form.id + ') - ('+ this.crud.form.name + ')?', {
          title: 'Borrar Oportunidad',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.eliminarLeads(this.crud.form.id);

            result.then((response) => {      
              this.modal.form.active = false        
              loader.hide()
              this.filterQuery()
              this.$awn.success("Registro eliminado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
      save() {
        this.$bvModal.msgBoxConfirm('¿Desea continuar con la operación?', {
          title: 'Guardar Oportunidad',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'Aceptar',
          cancelVariant: 'outline-dark',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'black',
          hideHeaderClose: false,
          centered: false
        })
        .then(value => {
          if (value) {      
            let loader = this.$loading.show();                      
            
            var customFiscalConditions = this.crud.form.fiscal_conditions_id
            var customDocumentsTypes = this.crud.form.documents_types_id
            var customDocumentsFiscalTypes = this.crud.form.documents_fiscal_types_id
            var customMaritalStatus = this.crud.form.marital_status_id
            var customLostMotives = this.crud.form.lost_motives_id
            var customCategories = this.crud.form.categories_id
            var customSources = this.crud.form.sources_id            
            var customBudget = this.crud.form.budget_id            
            var customGender = this.crud.form.gender
            var customStatus = this.crud.form.status
                        
            this.crud.form.fiscal_conditions_id = this.crud.form.fiscal_conditions_id ? this.crud.form.fiscal_conditions_id.code : null
            this.crud.form.documents_types_id = this.crud.form.documents_types_id ? this.crud.form.documents_types_id.code : null
            this.crud.form.documents_fiscal_types_id = this.crud.form.documents_fiscal_types_id ? this.crud.form.documents_fiscal_types_id.code : null
            this.crud.form.marital_status_id = this.crud.form.marital_status_id ? this.crud.form.marital_status_id.code : null
            this.crud.form.lost_motives_id = this.crud.form.lost_motives_id ? this.crud.form.lost_motives_id.code : null
            this.crud.form.categories_id = this.crud.form.categories_id ? this.crud.form.categories_id.code : null
            this.crud.form.sources_id = this.crud.form.sources_id ? this.crud.form.sources_id.code : null               
            this.crud.form.budget_id = this.crud.form.budget_id ? this.crud.form.budget_id.code : null
            
            if(this.crud.form.gender) {
              this.crud.form.gender = this.crud.form.gender.code
            } else {
              this.crud.form.gender = null
            }
            
            if(this.crud.form.status) {
              this.crud.form.status = this.crud.form.status.code
            } else {
              this.crud.form.status = null
            }

            if (this.crud.form.id) {
              var result = serviceAPI.editarLeads(this.crud.form);
            } else {
              var result = serviceAPI.agregarLeads(this.crud.form);
            }

            result.then((response) => {
              this.modal.form.active = false
              loader.hide()
              this.filterQuery()
              this.$awn.success("Datos guardados con éxito");
            })
            .catch(error => {              
              this.crud.form.fiscal_conditions_id = customFiscalConditions
              this.crud.form.documents_types_id = customDocumentsTypes
              this.crud.form.documents_fiscal_types_id = customDocumentsFiscalTypes
              this.crud.form.marital_status_id = customMaritalStatus
              this.crud.form.lost_motives_id = customLostMotives
              this.crud.form.categories_id = customCategories
              this.crud.form.sources_id = customSources
              this.crud.form.budget_id = customBudget
              this.crud.form.gender = customGender
              this.crud.form.status = customStatus
              
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {          
          this.$awn.alert(Error.showError(error));
        })               
      },   

      // FILTER
      filterLoad() {                
        this.filterLoadStaff()     
        this.filterLoadSellers()           
        this.filterLoadType()
        this.filterLoadArchive()
                
        this.restoreFilterStorage() 
      },
      filterLoadStaff() {
        var result = serviceAPI.obtenerStaff()        
        result.then((response) => {
          var data = response.data    

          this.arr.select.staff = []
          data.forEach(element => {                            
            if(!element.seller) {             
              this.arr.select.staff.push({ code: element.id, label: element.name })    
            }                    
          });          
        })   
      },
      filterLoadSellers() {
        var result = serviceAPI.obtenerVendedores()        
        result.then((response) => {
          var data = response.data    

          this.arr.select.sellers = []
          data.forEach(element => {                            
            this.arr.select.sellers.push({ code: element.id, label: element.name })            
          });          
        })   
      },    
      filterLoadType() {
        this.arr.select.type = []        
        this.arr.select.type.push({ code: 'customer', label: 'Cliente' })                        
        this.arr.select.type.push({ code: 'person', label: 'Persona' })                        
        this.arr.select.type.push({ code: 'company', label: 'Empresa' })                                
      },
      filterLoadArchive() {
        this.arr.select.archive = []        
        this.arr.select.archive.push({ code: false, label: 'VISIBLES' })                        
        this.arr.select.archive.push({ code: true, label: 'ARCHIVADOS' })                                
      },
      filterQuery(){
        let loader = this.$loading.show()   
   
        var result = serviceAPI.filtrarLeads(this.filter)        
        result.then((response) => {
          loader.hide()

          var data = response.data
          this.table.items = data
          this.arr.leads = data
          
          if(this.table.rowSelected!=null) {
            this.table.items[this.table.rowSelected]._showDetails = true                                        
          } 
          
          this.saveFilterStorage()
        })
        .catch(error => {
          loader.hide()                    
          this.$awn.alert(ErrorToken.valid(error))          
        });          
      }, 

      // SELECT CONTROLS
      selectLoad(){
        this.selectGender()     
        this.selectStatus() 
        this.selectLostMotives()
        this.selectCategories()
        this.selectSources()
        this.selectFiscalConditions()
        this.selectTypesDocuments()
        this.selectTypesDocumentsFiscal()
        this.selectMaritalStatus()
        this.selectContracts()        
      },
      selectGender(){
        this.arr.select.gender = []        
        this.arr.select.gender.push({ code: 'male', label: 'Masculino' })                        
        this.arr.select.gender.push({ code: 'female', label: 'Femenino' })                        
        this.arr.select.gender.push({ code: 'others', label: 'Otros' })      
      },
      selectStatus(){        
        this.arr.select.status = []        
        this.arr.select.status.push({ code: 'interested', label: 'Oportunidad' })                        
        this.arr.select.status.push({ code: 'in process', label: 'Relevamiento' })                        
        this.arr.select.status.push({ code: 'approval', label: 'Presupuesto' })                        
        this.arr.select.status.push({ code: 'won', label: 'Ganado' })                        
        this.arr.select.status.push({ code: 'lost', label: 'Perdido' })                        
      },
      selectLostMotives(){
        var result = serviceAPI.obtenerLeadsLostMotive()        
        result.then((response) => {
          var data = response.data    

          this.arr.select.lostMotives = []
          data.forEach(element => {                                                  
            this.arr.select.lostMotives.push({ code: element.id, label: element.name })                
          });          
        })  
      },  
      selectCategories(){
        var result = serviceAPI.obtenerLeadsCategories()        
        result.then((response) => {
          var data = response.data    

          this.arr.select.categories = []
          data.forEach(element => {                                                  
            this.arr.select.categories.push({ code: element.id, label: element.name })                
          });          
        })  
      },  
      selectSources(){
        var result = serviceAPI.obtenerLeadsSources()        
        result.then((response) => {
          var data = response.data    

          this.arr.select.sources = []
          data.forEach(element => {                                                  
            this.arr.select.sources.push({ code: element.id, label: element.name })                
          });          
        })  
      },  
      selectFiscalConditions(){
        var result = serviceAPI.obtenerLeadsFiscalConditions()        
        result.then((response) => {
          var data = response.data    

          this.arr.select.fiscalConditions = []
          data.forEach(element => {                                                  
            this.arr.select.fiscalConditions.push({ code: element.id, label: element.name })                
          });          
        })  
      },  
      selectTypesDocuments(){
        var result = serviceAPI.obtenerLeadsTypesDocuments()        
        result.then((response) => {
          var data = response.data    

          this.arr.select.typesDocuments = []
          data.forEach(element => {                                                  
            this.arr.select.typesDocuments.push({ code: element.id, label: element.name })                
          });          
        })  
      },  
      selectTypesDocumentsFiscal(){
        var result = serviceAPI.obtenerLeadsTypesDocumentsFiscal()        
        result.then((response) => {
          var data = response.data    

          this.arr.select.typesDocumentsFiscal = []
          data.forEach(element => {                                                  
            this.arr.select.typesDocumentsFiscal.push({ code: element.id, label: element.name })                
          });          
        })  
      },  
      selectMaritalStatus(){
        var result = serviceAPI.obtenerLeadsMaritalStatus()        
        result.then((response) => {
          var data = response.data    

          this.arr.select.maritalStatus = []
          data.forEach(element => {                                                  
            this.arr.select.maritalStatus.push({ code: element.id, label: element.name })                
          });          
        })  
      },  
      selectContracts() {
        var result = serviceAPI.obtenerContrato()        
        result.then((response) => {
          var data = response.data    

          this.arr.select.contracts = []
          data.forEach(element => {                                                  
            this.arr.select.contracts.push({ code: element.id, label: element.name })                
            this.arr.contracts.push(element)                
          });          
        })  
      },
      selectBudgets(customers_id=null, budget=null) {
        var params = {
          status: [{code: "open"}],          
          recurrent: {code: this.crud.form.work_recurrent},          
        }

        if(customers_id) {          
          params.customers = [{code: customers_id}]
        }

        var result = serviceAPI.filtrarPresupuesto(params)        
        result.then((response) => {
          var data = response.data.data

          this.arr.select.budgets = []
          data.forEach(element => {                       
            let label = moment(element.date).format('DD/MM/YYYY') + ' | ' +
                        element.customer.name + ' | ' + 
                        element.type_voucher.name + ' ' + 
                        element.point_sale.point_sale.toString().padStart(4,'0') + '-' + 
                        element.number.toString().padStart(8,'0') + ' | ' +
                        Intl.NumberFormat('es-AR',{style:'currency',currency: element.currency.code, maximumFractionDigits: 0}).format(element.amount_total) + ' | ' +
                        (element.recurrent ? 'Servicio Recurrente' : 'Proyecto Único')

            this.arr.select.budgets.push({ code: element.id, label: label })                              
          });    
          
          if(budget) {
            console.log(budget)
            let label = moment(budget.date).format('DD/MM/YYYY') + ' | ' +
                        budget.customer.name + ' | ' + 
                        budget.type_voucher.name + ' ' + 
                        budget.point_sale.point_sale.toString().padStart(4,'0') + '-' + 
                        budget.number.toString().padStart(8,'0') + ' | ' +
                        Intl.NumberFormat('es-AR',{style:'currency',currency: budget.currency.code, maximumFractionDigits: 0}).format(budget.amount_total) + ' | ' +
                        (budget.recurrent ? 'Servicio Recurrente' : 'Proyecto Único')
            
            setTimeout(() => {
              this.crud.form.budget_id = { code: budget.id, label: label }                             
            }, 1000);            
          } else {
            this.crud.form.budget_id = null
          }
        }) 
      },

      // LOAD CONTROL 
      loadCurrency (object) {
        if(object){
          this.crud.form.work_currency = object
          this.crud.form.work_currency_id = object.id
        } else {
          this.crud.form.work_currency = null
          this.crud.form.work_currency_id = 0
        }
      },        
      loadCustomers (object) {        
        if(object){
          this.crud.form.customers = object
          this.crud.form.customers_id = object.id    
                    
          this.selectBudgets(object.id)
        } else {
          this.crud.form.customers = null
          this.crud.form.customers_id = null
        }
      },  
      loadSellers (object) {        
        if(object){
          this.crud.form.sellers = object
          this.crud.form.sellers_id = object.id        
        } else {
          this.crud.form.sellers = null
          this.crud.form.sellers_id = null
        }
      },  
      loadStaff (object) {        
        if(object){
          this.crud.form.staff = object
          this.crud.form.staff_id = object.id        
        } else {
          this.crud.form.staff = null
          this.crud.form.staff_id = null
        }
      },                   

      // GETTERS
      getGenderSelect(value) {
        var result = null
        this.arr.select.gender.forEach(element=>{
          if(element.code==value) {
            result = element
          }
        })
        return result
      },
      getStatusSelect(value) {
        var result = null
        this.arr.select.status.forEach(element=>{
          if(element.code==value) {
            result = element
          }
        })
        return result
      },  
      getStatusName(value) {
        var name = ''
        this.arr.select.status.forEach(element=>{
          if(element.code==value) {
            name = element.label
          }
        })
        return name
      },
      getTotalByStatus(value) {
        var count = 0
        this.arr.leads.forEach(element=>{
          if(value == element.status) {
            count++ 
          }         
        })
        
        var msj = "Items"
        if(count <= 1) {
          msj = "Item"
        }
        return '<b>' + Intl.NumberFormat('es-AR',{ maximumFractionDigits: 0 }).format(count) + ' ' + msj + '</b>'        
      },
      getStatusTable(value) {
        var result = null
        this.arr.select.status.forEach(element=>{
          if(element.code==value) {
            var color = ''
            if(element.code == 'interested') {
              color = 'darkblue'
            }
            if(element.code == 'in process') {
              color = 'darkorange'
            }
            if(element.code == 'approval') {
              color = 'darkmagenta'
            }
            if(element.code == 'won') {
              color = 'darkgreen'
            }
            if(element.code == 'lost') {
              color = 'darkred'
            }

            result = {
              code: element.code,
              label: element.label,
              color: color,
            }
          }
        })
        return result
      }, 

      // FUNCTIONS --> EXTERNAL APPROVAL
      irDetail(item){        
        this.$router.push({ name: 'SalesFlowStaffDetailLeads', params: {leadsID: item.id} })
      },
      goService() {
        this.$router.push({ name: 'ServicesStaffCrudServicesCustomers'}) 
      },      
      externalApproval(item){ 
        let loader = this.$loading.show();
        var result = serviceAPI.enviarNotificationExternalApproval({
          id: item.id
        });

        result.then((response) => {              
          loader.hide()          
          this.$awn.success("Notificación enviada por email");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })        
        //this.$router.push({ name: 'SalesFlowPublicApproval', params: {leadsID: item.id} })
      },

      // FUNCTIONS --> CONTRACT
      openContract(item) {
        this.modal.contract.content = ''
        this.modal.contract.urlPDF = ''
        this.modal.contract.contractObject = null
        this.modal.contract.leadID = item.id      
        this.modal.contract.leadObject = item

        this.modal.contract.title = "Contrato"
        this.modal.contract.active = true        
      },
      showContract(item) {
        this.modal.contract.urlPDF = ''        
        this.modal.contract.content = ''        
        if(item) {                  
          this.arr.contracts.forEach(element => {            
            if(element.id == item.code) {
              this.modal.contract.content = element.content              
            }
          });
        }      
      },
      printContract() {
        if(!this.modal.contract.content) {
          this.$awn.alert("Seleccione un tipo de contrato")
          return false
        }

        let loader = this.$loading.show()
        this.modal.contract.urlPDF = ''

        var result = serviceAPI.crearContrato({
          contractID: this.modal.contract.contractObject.code,
          leadID: this.modal.contract.leadID,
        })        
        result.then((response) => {        
          var data = response.data              
          this.modal.contract.urlPDF = data
          loader.hide()          
        })     
        .catch(error => {
          loader.hide()                    
          this.$awn.alert(Error.showError(error))
        });                                
      },

      // FUNCTIONS --> FIRM
      openChangeFirm(item) {
        this.modal.firm.leadID = item.id
        if(item.firm.length>0) {
          this.modal.firm.imagen = [{ path: item.firm }]
        } else {
          this.modal.firm.imagen = []
        }

        this.modal.firm.title = "Firma"
        this.modal.firm.active = true       
      },
      changeFirm(){
        let loader = this.$loading.show();

        var image = new FormData();
        this.modal.firm.imagen.forEach((value, key) => {
          image.append(key, value.path);
        })
                
        var result = serviceAPI.changeFirmLeads(JSON.stringify({
          leadID: this.modal.firm.leadID,
          image: 0
        }), image);        

        result.then((response) => {          
          this.modal.firm.active = false
          loader.hide()
          this.filterQuery()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },

      // FUNCTIONS --> SERVICES
      createService(item) {
        this.$bvModal.msgBoxConfirm('¿Desea crear el servicio?', {
          title: 'Crear Servicio',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'Aceptar',
          cancelVariant: 'outline-dark',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'black',
          hideHeaderClose: false,
          centered: false
        })
        .then(value => {
          if (value) {              
            let loader = this.$loading.show();
            var result = serviceAPI.createServices(item);

            result.then((response) => {              
              loader.hide()   
              this.filterQuery()       
              this.$awn.success("Servicio Creado y Vinculado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })        
            //this.$router.push({ name: 'SalesFlowPublicApproval', params: {leadsID: item.id} })        
          }
        })
        .catch(error => {          
          this.$awn.alert(Error.showError(error));
        })                              
      },
      unassignService(item) {
        this.$bvModal.msgBoxConfirm('¿Desea desvincular el servicio?', {
          title: 'Desvincular Servicio',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Aceptar',
          cancelVariant: 'outline-danger',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'black',
          hideHeaderClose: false,
          centered: false
        })
        .then(value => {
          if (value) {          
            let loader = this.$loading.show();
            var result = serviceAPI.unassignService(item);

            result.then((response) => {              
              loader.hide()    
              this.filterQuery()      
              this.$awn.success("Servicio Desvinculado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })        
            //this.$router.push({ name: 'SalesFlowPublicApproval', params: {leadsID: item.id} })                
          }
        })
        .catch(error => {          
          this.$awn.alert(Error.showError(error));
        })              
      },

      // STATUS
      nextStage(item){                
        if(item.status == 'approval') {             
          this.crud.form.lost_motives_id = null
          this.modal.stage.value = ''
          this.modal.stage.id = item.id

          this.modal.stage.title = "Etapa Final"
          this.modal.stage.active = true
        } else {
          let loader = this.$loading.show();     

          var data = {
            id: item.id,   
            status: this.setNextStage(item.status)
          }                         
          var result = serviceAPI.setStatusLeads(data);        

          result.then((response) => {          
            loader.hide()
            this.filterQuery()
            this.$awn.success("Datos guardados con éxito");
          })
          .catch(error => {                        
            loader.hide()
            this.$awn.alert(Error.showError(error));
          })
        }
      },
      showNextStage(item){      
        if(item.status == 'won' || item.status == 'lost') {
          return false
        } else {
          return true
        }
      },
      previousStage(item){
        let loader = this.$loading.show();     
        var data = {
          id: item.id,    
          status: this.setPreviousStage(item.status)      
        }                         
        var result = serviceAPI.setStatusLeads(data);        

        result.then((response) => {          
          loader.hide()
          this.filterQuery()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {                        
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },  
      showPreviousStage(item){
        if(item.status == 'interested') {
          return false
        } else {
          return true
        }
      },
      setNextStage(value) {
        var nextStage = ''
        if(value == 'interested') {
          nextStage = 'in process'
        }
        
        if(value == 'in process') {
          nextStage = 'approval'
        }
        return nextStage
      },
      setPreviousStage(value) {
        var nextStage = ''
        if(value == 'won' || value == 'lost') {
          nextStage = 'approval'
        }
        
        if(value == 'approval') {
          nextStage = 'in process'
        }

        if(value == 'in process') {
          nextStage = 'interested'
        }
        return nextStage
      },
      saveStageFinish() {
        let loader = this.$loading.show();     

        var data = {
          id: this.modal.stage.id,   
          status: this.modal.stage.value,
          lostMotive: this.crud.form.lost_motives_id ? this.crud.form.lost_motives_id.code : null 
        }                         
        var result = serviceAPI.setStatusLeads(data);        

        result.then((response) => {  
          this.modal.stage.active = false        
          loader.hide()
          this.filterQuery()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {                        
          this.modal.stage.active = false
          loader.hide()
          this.$awn.alert(Error.showError(error));

          this.kanban.forceUpdate = this.kanban.forceUpdate + 1
          this.loadSalesFlowKanban()   
        })
      },
      closeStageFinish() {
        this.modal.stage.active=false

        this.kanban.forceUpdate = this.kanban.forceUpdate + 1
        this.loadSalesFlowKanban()       
      },
      validStatusAction(item, newStatus) {
        var action = ''
        if(item.status == 'interested') {
          if(newStatus == 'in process') {
            action = 'next'
          }                  
        } 
        
        if(item.status == 'in process') {
          if(newStatus == 'approval') {
            action = 'next'
          } 
          if (newStatus == 'interested') {
            action = 'prev'
          }
          if(newStatus == 'lost') {
            action = 'next'
          }                    
        } 
        
        if(item.status == 'approval') {
          if(newStatus == 'won' || newStatus == 'lost') {
            action = 'next'
          } 
          if (newStatus == 'in process') {
            action = 'prev'
          }
          if(newStatus == 'lost') {
            action = 'next'
          }                    
        } 
        
        if(item.status == 'won') {
          if(newStatus == 'approval') {
            action = 'prev'
          }
        } 
        
        if(item.status == 'lost') {
          if(newStatus == 'approval') {
            action = 'prev'
          }
        }         
        return action
      },

      // FILTROS SAVE
      saveFilterStorage() {
        Storage.setValue('filter_sales_flow_leads', JSON.stringify(this.filter))
      },
      restoreFilterStorage() {
        if(Storage.getValue('filter_sales_flow_leads')) {
          this.filter = JSON.parse(Storage.getValue('filter_sales_flow_leads'))
        }         
      },  
      saveStatusTabsStorage() {
        Storage.setValue('tabs_view_sales_flow', JSON.stringify(this.tabs))
      },
      restoreStatusTabsStorage() {
        if(Storage.getValue('tabs_view_sales_flow')) {
          this.tabs = JSON.parse(Storage.getValue('tabs_view_sales_flow'))
        }         
      },        
      
      // LOAD TABS
      disableAllTabs() {
        this.tabs.list = false
        this.tabs.kanban = false
      },      
      loadSalesFlowList() {
        this.disableAllTabs()
        this.tabs.list = true
        this.saveStatusTabsStorage()  
      },
      loadSalesFlowKanban(forceSaveTabs=false) {
        if(forceSaveTabs) {
          this.disableAllTabs()
          this.tabs.kanban = true
          this.saveStatusTabsStorage()
        }

        this.kanban.stages = []
        if(this.arr.select.status) {
          this.arr.select.status.forEach(element => {
            this.kanban.stages.push(element.code)
          });                   
        }
        if(this.arr.leads) {
          this.kanban.blocks = []
          this.arr.leads.forEach(element => {
            this.kanban.blocks.push({
              id: element.id,
              status: element.status,
              title: element.name,
              data: element,
            })
          });          
        }
      },

      // ARCHIVE LEAD
      archiveLead(item) {
        let loader = this.$loading.show();        
        
        var result = serviceAPI.guardarCheckArchive({
          id: item.id
        });

        result.then((response) => {          
          loader.hide()

          this.filterQuery()

          var msj = ''
          if(item.archive) {
            msj = "RESTAURADO"
          } else {
            msj = "ARCHIVADO"
          }
          
          this.$awn.success("Datos guardados con éxito. " + msj);
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },
      
      // CONTACT CUSTOMERS
      openContactsCustomers(item) {        
        this.modal.contacts.active = true
        this.modal.contacts.title = "Contactos del Cliente"
        this.modal.contacts.customer = item.customer        
      },
      addComment(item) {
        let loader = this.$loading.show();     

        this.crud.formHistory.leads_id = item.id
        this.crud.formHistory.staff_id = Helper.getStaffId()        
        var result = serviceAPI.agregarHistoria(this.crud.formHistory);

        result.then((response) => {          
          loader.hide()

          this.filterQuery()
          
          this.crud.formHistory.leads_id = null
          this.crud.formHistory.staff_id = Helper.getStaffId()        
          this.crud.formHistory.comment = ''

          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {                             
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },
      removeComment(item) {
        this.$bvModal.msgBoxConfirm('¿Borrar comentario?', {
          title: 'Borrar Comentario',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.eliminarHistoria(item.id);

            result.then((response) => {                                  
              this.filterQuery()
              
              setTimeout(()=> {
                var _item = null
                if(this.table.items) {
                  this.table.items.forEach(element => {                  
                    if(element.id == item.leads_id) {                    
                      _item = element                    
                    }
                  });
                }
                this.openHistory(_item)

                loader.hide()
                
                this.$awn.success("Registro eliminado");
              },1000)                            
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
      previewPopoverHistory(item) {  
        let style = '<style>p, ul {margin-bottom: 5px !important;} hr {margin: 0px !important;}</style>'              
        var history = ''
        if(item.history) {
          
          var arrReverseHistory = [...item.history].reverse()

          arrReverseHistory.forEach((element, index) => {         
            if(arrReverseHistory.length - 1 != index) {
              history += moment(element.date).format('DD MMMM') + ' - ' + element.comment + '<hr>'
            } else {
              history += moment(element.date).format('DD MMMM') + ' - ' + element.comment
            }              
          });
        }
        return style + history
      },
      openHistory(item) {                
        if(item.history) {          
          this.modal.history.active = true
          this.modal.history.title = "Historial de la Oportunidad"

          var arrReverseHistory = [...item.history].reverse()

          this.tableHistory.items = arrReverseHistory
        }
      },

      // BUDGET
      sendPrinterBudget(item) {
        this.crud.print.key=Math.random()
        this.crud.print.id=item.id
      },

      // KANABN
      updateBlock(id, status) {    
        var obj = null
        this.arr.leads.forEach(element => {
          if(element.id == id) {
            obj = element
          }
        });    

        if(status != 'won' && status != 'lost') {
          if(this.validStatusAction(obj, status) == 'next'){
            this.nextStage(obj)               
            return true
          } 
          
          if(this.validStatusAction(obj, status) == 'prev'){
            this.previousStage(obj)               
            return true          
          }         

          this.$awn.alert("No puede realizar esta operación");
          this.kanban.forceUpdate = this.kanban.forceUpdate + 1
          this.loadSalesFlowKanban()        
        } 
        
        if(status == 'won' || status == 'lost') {
          this.crud.form.lost_motives_id = null
          this.modal.stage.value = status
          this.modal.stage.id = id

          this.modal.stage.title = "Etapa Final"
          this.modal.stage.active = true          
        }
      }, 
    }    
  }
</script>

<style>
  .crud-leads-select {
    background: #fff;
  }
  .crud-leads-bg-form {
    background: #e4e5e64d;
  }
  .crud-leads-table-title-person {
    color: darkolivegreen;
    text-decoration: underline;
    font-weight: 800;
    font-size: 15px;
  }
  .crud-leads-table-title-company {
    color: darkslategray;
    text-decoration: underline;
    font-weight: 800;
    font-size: 15px;
  }  
  .crud-leads-table-title {
    color: black;
    text-decoration: underline;
    font-weight: 500;    
  }    
  .crud-leads-table-title-name {
    color: black;    
    font-weight: 700;    
  }   
  .crud-leads-table-title-status {        
    font-weight: 700;    
  }
  .crud-leads-modal-customer-won {
    color: darkgreen;
    font-size: 18px;
  }       
  .crud-leads-modal-customer-lost {
    color: darkred;
    font-size: 18px;
  }
  .crud-leads-title-attachment {
    font-size: 17px;
  }
  .crud-lead-print-icon {
    color: #000 !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
    font-size: 18px;
  }  
  .crud-lead-rocket-icon {
    color: #000 !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
    font-size: 18px;
  }    
  .crud-leads-preview-contract {
    background: #e5e5e5;    
    padding: 15px;
    height: 450px;        
  }

  .crud-leads-preview-contract .crud-leads-preview-contract-alert {
    background: #fff;        
  }
  .crud-leads-preview-contract .crud-leads-preview-contract-html {
    background: #fff;
    height: 450px;     
    padding: 15px;   
    overflow: overlay;
  }  
  .crud-leads-preview-open-pdf {
    margin-top: 10px;
  }
  .sales-flow-leads-image .image-container{
    width: 100%
  }

  .crud-lead-kanban-create {
    color: black;
    font-size: 10px;
    margin-top: 5px;
  }
  .crud-lead-kanban-create:hover {
    font-weight: bold;
    color: black;
    font-size: 10px;    
  }

  .crud-lead-kanban-unassign {
    color: darkred;
    font-size: 10px;
    margin-top: 5px;
  }
  .crud-lead-kanban-unassign:hover {
    font-weight: bold;
    color: darkred;
    font-size: 10px;
  }  
</style>

<style>
  .drag-column-interested {
    background: darkblue;
    color: white;
  }
  .drag-column-in {
    background: darkorange;
    color: white;
  }
  .drag-column-approval {
    background: darkmagenta;
    color: white;
  }    
  .drag-column-won {
    background: darkgreen;
    color: white;
  }    
  .drag-column-lost {
    background: darkred;
    color: white;
  }        
  .drag-item{  
    background: rgba(255, 255, 255, 0.4);
  }
  .sales-flow-kanban-date {
    position: absolute;
    right: 20px;
  }
  .sales-flow-kanban-hr {
    margin-top: 5px;
    margin-bottom: 5px;
    border: 0;
    border-top: 1px solid rgb(255 255 255);
  }
</style>
<style scoped>
  .b-avatar {
    width: 25px;
    height: 25px;    
  }  
</style>